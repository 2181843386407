import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * The 'layout' meta parameter indicates the page layout to use for that view; see plugins/layouts.js for options.
 * Authentication is required by default for all views. The `isPublic` meta parameter indicates that a view
 * is public and does not require authentication to display.
 */
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/Front.vue'),
        },
        {
            path: '/signup',
            name: 'signup',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue'),
        },
        {
            path: '/login',
            name: 'login',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "interaction" */ '../views/Interaction.vue'),
        },
        {
            path: '/profile',
            name: 'profile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Index.vue'),
        },
        {
            path: '/profile/setup',
            name: 'profile-setup',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-setup" */ '../views/profile/Setup.vue'),
        },
        {
            path: '/profile/authn/loginfront',
            name: 'profile-settings-authn-loginfront',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authn-loginfront" */ '../views/profile/authn/LoginFront.vue'),
        },
        {
            path: '/profile/authz',
            name: 'profile-settings-authz',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authz" */ '../views/profile/Authz.vue'),
        },
        {
            path: '/profile/session',
            name: 'profile-settings-session',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-session" */ '../views/profile/Session.vue'),
        },
        {
            path: '/service',
            name: 'service-admin',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "service-admin" */ '../views/service/Index.vue'),
        },
        {
            path: '/service/setup',
            name: 'service-admin-setup',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "service-admin-setup" */ '../views/service/Setup.vue'),
        },
        // {
        //     path: '/service/dns',
        //     name: 'service-admin-dns',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "service-admin-dns" */ '../views/service/Dns.vue'),
        // },
        {
            path: '/dashboard',
            name: 'user-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-dashboard" */ '../views/user/Dashboard.vue'),
        },
        {
            path: '/user/create/account',
            name: 'user-create-account',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-create-account" */ '../views/user/CreateAccount.vue'),
        },
        {
            path: '/account',
            name: 'user-account-list',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-account-list" */ '../views/user/AccountList.vue'),
        },
        {
            path: '/account/:accountId',
            name: 'account-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-dashboard" */ '../views/account/Dashboard.vue'),
        },
        {
            path: '/account/:accountId/delete',
            name: 'account-delete',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-delete" */ '../views/account/Delete.vue'),
        },
        {
            path: '/account/:accountId/create/client-token',
            name: 'account-create-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-client-token" */ '../views/account/CreateClientToken.vue'),
        },
        {
            path: '/account/:accountId/search/client',
            name: 'account-search-client',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-client" */ '../views/account/SearchClient.vue'),
        },
        {
            path: '/account/:accountId/search/client-token',
            name: 'account-search-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-client-token" */ '../views/account/SearchClientToken.vue'),
        },
        {
            path: '/account/:accountId/search/user',
            name: 'account-search-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-user" */ '../views/account/SearchUser.vue'),
        },
        // {
        //     path: '/account/:accountId/domain/:domain',
        //     name: 'account-view-domain',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "account-view-domain" */ '../views/account/ViewDomain.vue'),
        // },
        // {
        //     path: '/account/:accountId/image/:imageId/view',
        //     name: 'account-view-image',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "account-view-image" */ '../views/account/ViewImage.vue'),
        // },
        {
            path: '/account/:accountId/user/:userId',
            name: 'account-view-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-view-user" */ '../views/account/ViewUser.vue'),
        },
        {
            path: '/account/:accountId/edit/brandprofile',
            name: 'account-edit-brandprofile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-edit-brandprofile" */ '../views/account/EditBrandProfile.vue'),
        },
        {
            path: '/account/:accountId/client/:clientId',
            name: 'account-edit-client',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-edit-client" */ '../views/account/EditClient.vue'),
        },
        {
            path: '/account/:accountId/client-token/:clientTokenId/delete',
            name: 'account-delete-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-delete-client-token" */ '../views/account/DeleteClientToken.vue'),
        },
        {
            path: '/webauthz/grant',
            name: 'webauthz-grant',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-grant" */ '../views/webauthz/Grant.vue'),
        },
        {
            path: '/webauthz/prompt',
            name: 'webauthz-prompt',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-prompt" */ '../views/webauthz/Prompt.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;

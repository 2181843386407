<template>
    <nav class="no-print">
        <v-app-bar fixed fade-on-scroll elevation="1" dense color="white" app>
            <v-toolbar-title style="font-size: 24px!important;">
                <router-link :to="{ name: 'front' }" style="text-decoration: none" v-if="!account">
                    <span class="teal--text">Brand</span><span class="green--text text--lighten-2">Profile</span>
                    <!-- <g-image alt="BrandProfile" src="~/assets/logo/brandprofile/BrandProfile_light_256x48.png" class="mr-1 float-left" width="128" height="24" fit="inside"/> -->
                </router-link>
                <router-link :to="{ name: 'account-dashboard', params: { accountId: account.id } }" style="text-decoration: none;" v-if="account">
                    <span class="teal--text text--darken-2">{{ account.name }}</span>
                </router-link>
            </v-toolbar-title>
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
                color="teal"
            ></v-progress-linear>
            <v-spacer></v-spacer>
            <v-menu offset-y left open-on-click open-on-hover close-delay="100" v-if="isAuthenticated && $route.params.accountId">
                <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="teal--text">
                        <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
                    </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item :to="{ name: 'account-create-brand', params: { accountId: $route.params.accountId } }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'box']" class="teal--text" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                New Brand
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'account-create-domain', params: { accountId: $route.params.accountId } }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'folder']" class="teal--text" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                New Domain
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
            <!-- <v-btn icon color="teal" @click="search">
                <font-awesome-icon :icon="['fas', 'search']" fixed-width/>
            </v-btn> -->
            <v-menu v-model="helpMenu" offset-y open-on-click open-on-hover close-delay="100">
                <template #activator="{ on, attrs }">
                    <v-btn icon color="teal" v-bind="attrs" v-on="on">
                        <!-- TODO: when we upgrade to fontawesome 6.x this should be changed to 'message-question', or at least check how it looks that way -->
                        <font-awesome-icon :icon="['fas','question']" fixed-width/>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-text>
                        Questions or comments?
                        <a :href="contactURL" target="_blank">Contact us!</a>
                    </v-card-text>
                </v-card>
            </v-menu>
            <v-btn outlined color="teal" v-if="!isAuthenticated" @click="login">
                Sign in
            </v-btn>
            <v-menu offset-y left open-on-click open-on-hover close-delay="100" v-if="isAuthenticated">
                <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="teal--text">
                        <Avatar :attr="mainProfile" :size="36"/>
                    </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item :to="{ name: 'profile' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'user']" class="teal--text" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Profile
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'user-account-list' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'folder']" class="teal--text" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Accounts
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'user-dashboard' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'chart-line']" class="teal--text" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Dashboard
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'service-admin' }" v-if="isPermitServiceAdmin">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'id-badge']" class="teal--text" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Service Admin
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item @click="onLogout">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'shield-alt']" class="teal--text" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Logout
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Avatar from '@/components/Avatar.vue';

export default {
    components: {
        Avatar,
    },
    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
            account: (state) => state.account,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
        }),
        mainProfile() {
            return { name: this.user.name, email: this.user.email };
        },
        isPermitServiceAdmin() {
            return this.isAuthenticated && Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        contactURL() {
            const websiteURL = new URL(process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://brandprofile.org');
            websiteURL.pathname = '/contact/';
            return websiteURL.toString();
        },
    },
    methods: {
        login() {
            const { fullPath } = this.$router.currentRoute;
            this.$router.push({ name: 'login', query: { next: fullPath } });
        },
        async onLogout() {
            console.log('onLogout: redirecting to front page');
            await this.$store.dispatch('logout');
            if (this.$route.name !== 'front') {
                this.$router.push({ name: 'front' });
            }
        },
        search() {
            if (this.$route.name === 'search') {
                // reset the search input to empty string
                this.$bus.$emit('search', '');
            } else {
                this.$router.push({ name: 'search' /* , query: { q: this.searchQuery } */ });
            }
        },
    },
    mounted() {
        // console.log(`navbar route params ${JSON.stringify(this.$route.params)}`);
        // if (this.$route.params.realmId) {
        //     console.log(`navabar realm ${this.$route.params.realmId}`);
        // } else {
        //     console.log('navbar no realm');
        // }
    },
};
</script>

<style>
    @media print {
        .no-print {
            display: none !important;
        }
    }
    @media screen {
        div.menutabs div[role="tablist"] { width: 100%; }
        div.menutabs [role="tab"] { justify-content: flex-start; padding-left: 48px;}
    }
</style>

import Vue from 'vue';
import Vuex from 'vuex';

import client from '../sdk/client-factory';
import { toMillis } from '../sdk/time';

Vue.use(Vuex);

/*
Do not redirect user from here (no $router.push). Respond to calling code
with appropriate indicators and let the calling code redirect as needed.
*/

export default new Vuex.Store({
    state: {
        focus: null,
        isReady: false, // indicates that we loaded session info from server, so we know if user is authenticated; and if user is authenticated, that we've also loaded user info and account info from server
        serviceInfo: {}, // registration_mode, stripeTokenPublicKey
        // serviceVersion: {}, // version
        session: { isAuthenticated: false }, // isAuthenticated, notAfter, isCsrfGuardEnabled
        user: {}, // name, email, sessionIdleExpiresMillis
        account: null, // current account; populated when the path has the `accountId` parameter; see App.vue
        interactionMap: {}, // id => interaction ( type: string, next: string, state: object )
        nav: { queue: [] }, // queue  with items pushed to it, so whenever we are done with something and want to know where to return to, we pop the last item from the queue and go there; and each item can have a function to determine if it's still valid (and the user should be directed there) or if it should be ignored (remove from the queue and proceed to next item)
        loadingMap: { init: true },
        // noteList: [],
        clientPermitOriginProtocolChoices: [
            { text: 'HTTP', value: 'http' },
            { text: 'HTTPS', value: 'https' },
        ],
    },
    getters: {
        isLoading(state) {
            return Object.values(state.loadingMap).reduce((acc, item) => acc || item, false);
        },
    },
    mutations: {
        ready(state) {
            console.log('vuex store: ready');
            state.isReady = true;
        },
        focus(state, value) {
            state.focus = value;
        },
        setServiceInfo(state, serviceInfo) {
            state.serviceInfo = serviceInfo;
        },
        setSession(state, session) {
            state.session = session;
        },
        setUser(state, user) {
            state.user = user;
        },
        setAccount(state, account) {
            state.account = account;
        },
        setInteraction(state, interaction) {
            state.interactionMap = { ...state.interactionMap, ...interaction };
        },
        setNav(state, nav) {
            state.nav = nav;
        },
        loading(state, progress) {
            state.loadingMap = { ...state.loadingMap, ...progress };
        },
        // noteList(state, values) {
        //     state.noteList = [...values];
        // },
    },
    actions: {
        async createAccount({ commit, dispatch, state }, accountInfo) {
            commit('loading', { createAccount: true });
            const response = await client.user(state.session.userId).user.createAccount(accountInfo);
            if (response.isCreated) {
                await dispatch('loadSession');
                if (state.session.isAuthenticated) {
                    await dispatch('loadUser');
                }
            }
            commit('loading', { createAccount: false });
            return response;
        },
        async logout({ commit }) {
            commit('loading', { logout: true });
            await client.main().authn.logout();
            // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
            commit('setSession', { isAuthenticated: false });
            commit('loading', { logout: false });
        },
        // async enableCsrfGuard({ commit, state }) {
        //     const csrfTokenResponse = await client.authn.createCsrfToken();
        //     if (csrfTokenResponse.token) {
        //         const csrfGuardToken = csrfTokenResponse.token;
        //         localStorage.setItem('csrfGuardToken', csrfGuardToken);
        //         commit('setSession', { ...state.session, isCsrfGuardEnabled: true, csrfGuardToken });
        //     }
        // },
        async init({ commit, dispatch, state }, { force = false } = {}) {
            if (state.isReady && !force) {
                console.log('vuex store: init already done');
                return;
            }
            console.log('vuex store: init');
            commit('loading', { init: true });
            try {
                await Promise.all([
                    dispatch('loadServiceInfo'),
                    dispatch('loadSession'),
                ]);
                console.log('vuex store: loaded service info and session');
                /*
                if (state.session.isCsrfGuardEnabled && state.session.csrfGuardToken) {
                    // csrf guard enabled, store the token for use by loginshiedl client
                    localStorage.setItem('csrfGuardToken', state.session.csrfGuardToken);
                } else {
                    // enable csrf guard
                    await dispatch('enableCsrfGuard');
                }
                */
                if (state.session.isAuthenticated) {
                    // load data concurrently
                    await Promise.all([
                        dispatch('loadUser'),
                    ]);
                }
                console.log('vuex store: ready');
                commit('ready');
            } catch (err) {
                console.error('vuex store: init failed');
            }
            commit('loading', { init: false });
        },
        async refresh({ dispatch, state }) {
            console.log('vuex store: refresh');
            // not displaying loading bar because we want this to be transparent
            try {
                await dispatch('loadSession', { progressIndicator: false });
                if (state.session.isAuthenticated) {
                    await dispatch('loadUser', { progressIndicator: false });
                }
            } catch (err) {
                console.error('vuex store: refresh failed');
            }
        },
        async loadServiceInfo({ commit }) {
            commit('loading', { loadServiceInfo: true });
            try {
                const [/* versionInfo, */serviceInfo] = await Promise.all([
                    // client.service.getVersion(),
                    client.main().service.getInfo(),
                ]);
                // commit('setServiceVersion', versionInfo);
                commit('setServiceInfo', serviceInfo);
            } catch (err) {
                console.error('vuex store: failed to load service info');
            }
            commit('loading', { loadServiceInfo: false });
        },
        async loadSession({ commit, dispatch, state }, { progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadSession: true });
            }
            try {
                const sessionInfo = await client.main().authn.get();
                console.log(`vuex store: session ${JSON.stringify(sessionInfo)}`);
                const now = Date.now();
                const {
                    user_id: userId,
                    authenticated = false,
                    authenticated_duration: authenticatedDuration = null,
                    unlocked = false,
                    unlocked_duration: unlockedDuration = null,
                    // duration = null,
                    refresh_after_duration: refreshAfterDuration = null,
                    etag = {},
                } = sessionInfo;
                let { reloadTimeoutId } = state.session;
                if (authenticated && typeof refreshAfterDuration === 'number' && refreshAfterDuration > 0) {
                    // clear a previous timeout, if it exists
                    if (reloadTimeoutId) {
                        console.log(`vuex store: clearing timeout ${reloadTimeoutId}`);
                        clearTimeout(reloadTimeoutId);
                    }
                    // the setTimeout function has a maximum timeout
                    /*
                    NOTE:
                    On some browsers, setTimeout uses a 32-bit signed integer to store the delay,
                    and we provide a value greater than the maximum it overflows and runs the
                    callback function immediately.

                    For example, this works fine:
                    setTimeout(() => { console.log("hello"); }, 2147483647);

                    While asking for one millisecond more causes it to run immediately:
                    setTimeout(() => { console.log("hello"); }, 2147483648);

                    To avoid this behavior when the server provides a
                    very long timeout, we enforce a maximum refreshAfterDuration.
                    */
                    // const maxTimeoutDuration = 2147483647; // 2^31 - 1, max positive value for 32 bit signed integer
                    const maxTimeoutDuration = toMillis({ hours: 1 });
                    const effectiveRefreshAfterDuration = Math.min(refreshAfterDuration, maxTimeoutDuration);

                    console.log(`vuex store: scheduling session reload for ${effectiveRefreshAfterDuration} ms`);
                    reloadTimeoutId = setTimeout(() => {
                        console.log('vuex store: reloading session');
                        dispatch('loadSession');
                    }, effectiveRefreshAfterDuration);
                }
                commit('setSession', {
                    userId,
                    isAuthenticated: authenticated,
                    isUnlocked: unlocked,
                    authenticatedNotAfter: typeof authenticatedDuration === 'number' && authenticatedDuration > 0 ? now + authenticatedDuration : null,
                    unlockedNotAfter: typeof unlockedDuration === 'number' && unlockedDuration > 0 ? now + unlockedDuration : null,
                    nextRefresh: typeof refreshAfterDuration === 'number' && refreshAfterDuration > 0 ? now + refreshAfterDuration : null,
                    etag,
                    reloadTimeoutId,
                });
            } catch (err) {
                commit('setSession', { fault: { type: 'read-failed' } });
            }
            commit('loading', { loadSession: false });
        },
        async loadUser({ commit, state }, { progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadUser: true });
            }
            try {
                const userInfo = await client.user(state.session.userId).user.get();
                commit('setUser', userInfo);
            } catch (err) {
                commit('setUser', { fault: { type: 'read-failed' } });
            }
            commit('loading', { loadUser: false });
        },
        async loadAccount({ commit }, { accountId, progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadAccount: true });
            }
            try {
                const accountInfo = await client.account(accountId).currentAccount.get({ id: accountId });
                commit('setAccount', accountInfo);
            } catch (err) {
                console.log('loadAccount failed', err);
                commit('setAccount', null);
            }
            commit('loading', { loadAccount: false });
        },
        // async editSession({ commit }, sessionInfo) {
        //     commit('loading', { editSession: true });
        //     let isEdited = false;
        //     try {
        //         const newSessionInfo = await client.authn.edit(sessionInfo);
        //         commit('setSession', newSessionInfo);
        //         isEdited = true;
        //     } catch (err) {
        //         console.log('editSession error: %o', err);
        //     }
        //     commit('loading', { editSession: false });
        //     return isEdited;
        // },
        async editCurrentUser({ commit, state }, userInfo) {
            try {
                commit('loading', { editCurrentUser: true });
                const { isEdited } = await client.user(state.session.userId).user.edit(userInfo);
                // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
                const newUserInfo = { ...state.user, info: { ...state.user.info, ...userInfo } };
                commit('setUser', newUserInfo);
                return isEdited;
            } catch (err) {
                console.error('editCurrentUser error', err);
                return false;
            } finally {
                commit('loading', { editCurrentUser: false });
            }
        },
        async createInteraction({ commit }, interaction) {
            commit('loading', { createInteraction: true });
            console.log('store: createInteraction %o', interaction);
            const response = await client.main().interaction.create(interaction);
            if (response.id) {
                commit('setInteraction', { [response.id]: response });
            }
            commit('loading', { createInteraction: false });
            return response;
        },
        async editInteraction({ commit }, { interactionId, message }) {
            commit('loading', { editInteraction: true });
            console.log('store: editInteraction %s', interactionId);
            const response = await client.main().interaction.edit(interactionId, message);
            if (response.id) {
                commit('setInteraction', { [response.id]: response });
            }
            commit('loading', { editInteraction: false });
            return response;
        },
        async loadInteraction({ commit }, interactionId) {
            commit('loading', { loadInteraction: true });
            const response = await client.main().interaction.get(interactionId);
            commit('setInteraction', { [interactionId]: response });
            commit('loading', { loadInteraction: false });
            return response;
        },
        queueNavItem({ commit, state }, item) {
            const queue = [...state.nav.queue];
            queue.push({ path: item.path, query: item.query || {}, hash: item.hash || '' });
            commit('setNav', { queue });
        },
        nextNavItem({ commit, state }) {
            try {
                const queue = [...state.nav.queue];
                if (queue.length > 0) {
                    const next = queue.splice(queue.length - 1, 1);
                    commit('setNav', { queue });
                    return next[0];
                }
                if (state.session.isAuthenticated) {
                    return { path: '/dashboard' };
                }
                return { path: '/' };
            } catch (err) {
                console.log('forward error: %o', err);
                return { path: '/' };
            }
        },
        // notes
        // async fetchNoteList({ state }) {
        //     const { list } = await client.note.list();
        //     // console.log(`list note: ${J} id ${id}`);
        //     state.noteList = list;
        // },

    },
});

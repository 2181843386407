/*!
Copyright (C) 2020 Liberty Infrasystems. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

/*
async function getOctetStream(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/octet-stream',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}
*/

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

/* if this function is used, and server returns 202 Accepted with no json in the response body, axios will throw an error because of the unexpected response body; since we know the upload API returns 202 Accepted with no response body, we use postUpload instaead.
async function postUploadAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}
*/

/*
async function postUpload(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request, {
        headers: {
            'Content-Type': 'multipart/form-data',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}
*/

class CurrentAccount {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async get(request) {
        return getJson(`${this.url}`, request, { requestHeaders: this.requestHeaders });
    }

    async delete() {
        return postJsonAcceptJson(`${this.url}/delete/account`, null, null, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        return postJsonAcceptJson(`${this.url}/check/account`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class LinkAccountUser {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    // TODO: move this to an invitation table; link-account-user record will be created automatically when user accepts invitation
    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/create/link-account-user`, request, null, { requestHeaders: this.requestHeaders });
    // }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/link-account-user`, null, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/link-account-user`, request, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/link-account-user`, query, { requestHeaders: this.requestHeaders });
    }
}

class PartnerConnect {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async start(request = {}) {
        return postJsonAcceptJson(`${this.url}/rpc/start-partner-connect`, request, /* query: */ null, { requestHeaders: this.requestHeaders });
    }

    async verify(token) {
        return postJsonAcceptJson(`${this.url}/rpc/verify-partner-connect`, { token }, /* query: */ null, { requestHeaders: this.requestHeaders });
    }
}

class Setting {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/setting`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/setting`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/setting`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/setting`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/setting`, query, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorized client programs
 */
class Client {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/client`, request, null, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        return postJsonAcceptJson(`${this.url}/edit/client`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/client`, null, query, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/client`, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search clients assigned to this account
     */
    async search(query) {
        return getJson(`${this.url}/search/client`, query, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorization tokens for client software
 * using the server's Client API.
 */
class ClientToken {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/client-token`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/client-token`, null, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search available client tokens assigned to this account
     */
    async search(query) {
        return getJson(`${this.url}/search/client-token`, query, { requestHeaders: this.requestHeaders });
    }
}

class BrowserClient {
    constructor(context = {}) {
        this.currentAccount = new CurrentAccount(context);
        this.client = new Client(context);
        this.clientToken = new ClientToken(context);
        this.linkAccountUser = new LinkAccountUser(context);
        this.partnerConnect = new PartnerConnect(context);
        this.setting = new Setting(context);
    }
}

export default BrowserClient;

export {
    CurrentAccount,
    Client,
    ClientToken,
    LinkAccountUser,
    PartnerConnect,
    Setting,
};
